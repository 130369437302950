@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f6f9fd;
}
:root {
    color-scheme: only light;
}
