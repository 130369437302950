@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

// Variables
$dark-blue: #1e1b2d;
$light-blue: #828092;
$white: #fff;
$black: #000;
$open-sans: "Open Sans", sans-serif;

// clear-fix mixin
@mixin cf {
    &::before,
    &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
}

// Base styles
.card {
    float: left;
    padding: 1rem;
    aspect-ratio: 1.618;
    min-width: 300px;
    .menu-content {
        @include cf;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            display: inline-block;
        }
        a {
            color: $white;
        }
    }
    .wrapper {
        background-color: $white;
        min-height: 400px;
        position: relative;
        overflow: hidden;
        border-radius: 32px;
        filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
            drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
        &:hover {
            .data {
                transform: translateY(0px);
            }
            .content {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .data {
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translateY(calc(250px));
        transition: transform 0.3s;
        .content {
            padding: 1.5em;
            position: relative;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            box-shadow: 0 5px 30px 10px rgba($black, 0.3);
            height: 320px;
        }
    }
    .type {
        font-size: 12px;
    }
    .title {
        margin-top: -10px;
        margin-bottom: 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 32px;
        white-space: nowrap;
    }
    .text {
        height: 70px;
        margin: 0;
    }
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]:checked + .menu-content {
        transform: translateY(-60px);
    }
}

.project-card {
    .date {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #333;
        color: $white;
        padding: 0.7em 2.5em 1.5rem 0.7em;
        border-radius: 0 0 90% 0;
        span {
            display: block;
            text-align: center;
        }
        .day {
            font-weight: 700;
            font-size: 24px;
            text-shadow: 2px 3px 2px rgba($black, 0.18);
        }
        .month {
            text-transform: uppercase;
        }
        .month,
        .year {
            font-size: 10px;
        }
    }
    .title {
        a {
            color: white;
        }
    }
}

.math {
    background: url(../../resources/math.jpg) 30% 1% / cover no-repeat;
}

.science {
    background: url(../../resources/science.jpg) 30% 1% / cover no-repeat;
}

.english {
    background: url(../../resources/english.jpg) 30% 1% / cover no-repeat;
}
.testprep {
    background: url(../../resources/testprep.jpg) 30% 1% / cover no-repeat;
}
.foreignlanguage {
    background: url(../../resources/foreignlanguage.jpg) 30% 1% / cover
        no-repeat;
}
.music {
    background: url(../../resources/music.jpg) 30% 1% / cover no-repeat;
}
