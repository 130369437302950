:root {
    body {
        font-family: "Poppins", sans-serif;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        /* background-color: #e5e5e5; */
        background-color: white;
    }
}
