@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
.card {
    float: left;
    padding: 1rem;
    aspect-ratio: 1.618;
    min-width: 300px;
}
.card .menu-content {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.card .menu-content::before,
.card .menu-content::after {
    content: "";
    display: table;
}
.card .menu-content::after {
    clear: both;
}
.card .menu-content li {
    display: inline-block;
}
.card .menu-content a {
    color: #fff;
}
.card .wrapper {
    background-color: #fff;
    min-height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 32px;
    filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
        drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
}
.card .wrapper:hover .data {
    transform: translateY(0px);
}
.card .wrapper:hover .content {
    background-color: rgba(0, 0, 0, 0.8);
}
.card .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(250px);
    transition: transform 0.3s;
}
.card .data .content {
    padding: 1.5em;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
    height: 320px;
}
.card .type {
    font-size: 12px;
}
.card .title {
    margin-top: -10px;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 32px;
    white-space: nowrap;
}
.card .text {
    height: 70px;
    margin: 0;
}
.card input[type="checkbox"] {
    display: none;
}
.card input[type="checkbox"]:checked + .menu-content {
    transform: translateY(-60px);
}

.project-card .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333;
    color: #fff;
    padding: 0.7em 2.5em 1.5rem 0.7em;
    border-radius: 0 0 90% 0;
}
.project-card .date span {
    display: block;
    text-align: center;
}
.project-card .date .day {
    font-weight: 700;
    font-size: 24px;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
}
.project-card .date .month {
    text-transform: uppercase;
}
.project-card .date .month,
.project-card .date .year {
    font-size: 10px;
}
.project-card .title a {
    color: white;
}

.math {
    background: url(../../resources/math.jpg) 30% 1% / cover no-repeat;
}

.science {
    background: url(../../resources/science.jpg) 30% 1% / cover no-repeat;
}

.english {
    background: url(../../resources/english.jpg) 30% 1% / cover no-repeat;
}

.testprep {
    background: url(../../resources/testprep.jpg) 30% 1% / cover no-repeat;
}

.foreignlanguage {
    background: url(../../resources/foreignlanguage.jpg) 30% 1% / cover
        no-repeat;
}

.music {
    background: url(../../resources/music.jpg) 30% 1% / cover no-repeat;
} /*# sourceMappingURL=Card.css.map */
